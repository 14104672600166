import React from 'react'
import Leica from './Images/leica_logo.png'
import Apple from './Images/apple_logo.png'
import Sony from './Images/sony_logo.png'
import Nikon from './Images/nikon_logo.png'
import Canon from './Images/canon_logo.png'
import Samsung from './Images/samsung_logo.png'
import Mi from './Images/mi_logo.png'
import Huawei from './Images/huawei_logo.png'
import Oppo from './Images/oppo_logo.png'
import Realme from './Images/realme_logo.png'
import Vivo from './Images/vivo_logo.png'


const logos = [Leica, Apple, Sony, Nikon, Canon, Samsung, Mi, Huawei, Oppo, Realme, Vivo];

const SelectLogo = () => {
    const [logo, setLogo] = React.useState(Leica); // 默认使用Leica图标
    const [fileName, setFileName] = React.useState('');
    const handleLogoClick = (selectedLogo) => {
        setLogo(selectedLogo); // 更新显示的logo
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // 创建一个可以在 img 标签中使用的 URL
            const fileURL = URL.createObjectURL(file);
            setLogo(fileURL); // 设置为用户上传的图像
            setFileName(file.name); // 设置文件名
        }
    }

    return (
        
        <div className='flex flex-col items-center w-full'>
        
            
        
            <div className='flex overflow-x-auto fixed bottom-0 left-0 right-0 bg-white dark:bg-black shadow-sm'>
            <div className='flex max-w-5xl mx-auto p-5'>
            <input 
                id="file-upload"
                type="file" 
                accept="image/*" 
                onChange={handleFileChange} 
                className='hidden'
            />
            <label 
                className='cursor-pointer inline-block'
                htmlFor="file-upload"
            >
                <svg t="1731070661060" class="w-8 h-8 md:w-10 md:h-10" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14365" width="128" height="128"><path d="M67.661 517.598a447.654 455.375 0 1 0 895.308 0 447.654 455.375 0 1 0-895.308 0Z" fill="#5E7BF7" p-id="14366"></path><path d="M791.948 578.987H237.607c-6.6 0-12-5.4-12-12V460.209c0-6.6 5.4-12 12-12h554.341c6.6 0 12 5.4 12 12v106.779c0 6.599-5.4 11.999-12 11.999zM564.541 434.094h-103c-6.6 0-12-5.4-12-12V267.756c0-6.6 5.4-12 12-12h103c6.6 0 12 5.4 12 12v154.338c0 6.6-5.4 12-12 12zM564.541 776.143h-103c-6.6 0-12-5.4-12-12V609.805c0-6.6 5.4-12 12-12h103c6.6 0 12 5.4 12 12v154.338c0 6.6-5.4 12-12 12z" fill="#ffffff" p-id="14367"></path></svg>
            </label>
            
            {logos.map((logoItem, index) => (
                 <img
                        key={index}
                        src={logoItem}
                        alt={`Logo ${index}`}
                        className='w-8 h-8 md:w-10 md:h-10 object-cover cursor-pointer mx-2'
                        onClick={() => handleLogoClick(logoItem)}
                    />
                ))}
            </div>
           </div>
        </div>
    );
};

export default SelectLogo