import React from 'react'
import ThemeSwitch from './ThemeSwitch'

const Head = () => {
    return (
        <header className='flex justify-between items-center bg-white dark:bg-black shadow-sm'>
            <div className='flex max-w-5xl mx-auto p-5 outline-hidden'>
            <img src="https://tuya.one/assets/tuya-Szv8VDhg.png"alt="图鸭"style={{width: '100px'}}/>
            <div className="text-gray-300"><ThemeSwitch /></div>
            </div>
        </header>
    )
}

export default Head