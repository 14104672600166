import React, { useState, useContext } from 'react';
import SelectLogo from './SelectLogo';
import ImageContext from './GlobalContext';
import AddImage from './Icons/add_image.svg';
import Addplus from './Images/add.png';
import Leica from './Images/leica_logo.png';
import Apple from './Images/apple_logo.png';
import Sony from './Images/sony_logo.png';
import Nikon from './Images/nikon_logo.png';
import Canon from './Images/canon_logo.png';
import Samsung from './Images/samsung_logo.png';
import Mi from './Images/mi_logo.png';
import Huawei from './Images/huawei_logo.png';
import Oppo from './Images/oppo_logo.png';
import Realme from './Images/realme_logo.png';
import Vivo from './Images/vivo_logo.png';

const logos = [Leica, Apple, Sony, Nikon, Canon, Samsung, Mi, Huawei, Oppo, Realme, Vivo];

const Content = () => {
    const [phoneType, setPhoneType] = useState('XIAOMI 12S ULTRA');
    const [photoInfo, setPhotoInfo] = useState('120mm f/4.1 1/100 ISO90');
    const [photoLocation, setPhotoLocation] = useState(`'40°3'13"N 116°19'25"E'`);

    const { setImageName, setImageDate, lastModifiedDate } = useContext(ImageContext);
    const [logo, setLogo] = useState(Leica);
    const [fileName, setFileName] = useState('');
    const [imgSrc, setImgSrc] = useState('');


    const handleLogoClick = (selectedLogo) => {
        setLogo(selectedLogo); // 更新显示的logo
    };

    const handleLOGOChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // 创建一个可以在 img 标签中使用的 URL
            const fileURL = URL.createObjectURL(file);
            setLogo(fileURL); // 设置为用户上传的图像
            setFileName(file.name); // 设置文件名
        }
    }
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImageName(file.name);
        setImageDate(file.lastModified);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            setImgSrc(e.target.result)
        }
    }

    const triggerFileInput = () => {
        const fileInput = document.getElementById('select-image');
        fileInput.click();
    };


     const resetImage = () => {
        setImgSrc('');
        setImageName('');
    }

    return (
        <>
        <div id="camMaker">
        <div className="bg-white dark:bg-black shadow-sm">
            {!imgSrc ? (
                <div className="w-full h-60 md:h-80 cursor-pointer relative">
                    <div
                        className="absolute left-4 top-4 right-4 bottom-4 md:left-8 md:top-8 md:right-8 md:bottom-8 border-2 border-dashed border-gray-200 hover:border-gray-400 transition-all rounded-lg text-gray-400 text-xl items-center flex justify-center"
                        onClick={triggerFileInput}
                    >
                        <span className='flex justify-items-center h-6'>
                            <img src={AddImage} alt="" className='w-6 h-6 mr-2' /> 上传图片
                        </span>
                    </div>
                </div>
            ) : (
                <div className='relative'>
                    <button onClick={resetImage} className="absolute top-5 right-5 bg-white py-1 px-3 rounded-full transition-all opacity-0 group-hover:opacity-100">重新上传</button>
                    <img id='selected-image' src={imgSrc} className="block align-top w-full" alt='' />
                </div>
            )}

            <input type="file" accept="image/*" className="hidden" id='select-image' onChange={handleFileChange}></input>

            <div className="flex justify-between p-4 md:p-8 leading-none dark:text-white mb-20">
                <div className='m-0 md:h-16 h-10'>
                    <div className="font-medium md:text-lg text-sm">
                        <input type='text' className="bg-transparent border-none outline-none text-gray-700 dark:text-white md:h-8 h-5 " value={phoneType} onChange={(e) => setPhoneType(e.target.value)} />
                    </div>
                    <div className="opacity-40 dark:opacity-80 md:text-base text-xs">
                        <input type='datetime-local' className="bg-transparent border-none outline-none text-gray-700 dark:text-white md:h-8 h-5 " value={lastModifiedDate} onChange={(e) => setImageDate(e.target.value)} />
                    </div>
                </div>
                <div className="flex m-0 md:h-16 h-10">
                    <div className='md:w-16 md:h-16 w-10 h-10 md:mr-3 mr-2'>
                        <img src={logo} className='md:w-16 md:h-16 w-10 h-10 object-cover' alt='Logo' />
                    </div>
                    <div className='md:pl-3 pl-2 border-l-2 overflow-x-auto'>
                        <div className="font-medium md:text-lg text-sm">
                            <input type='text' className="bg-transparent border-none outline-none text-gray-700 dark:text-white md:h-8 h-5" value={photoInfo} onChange={(e) => setPhotoInfo(e.target.value)} />
                        </div>
                        <div className="opacity-40 dark:opacity-80 md:text-base text-xs">
                            <input type='text' className="bg-transparent border-none outline-none text-gray-700 dark:text-white md:h-8 h-5" value={photoLocation} onChange={(e) => setPhotoLocation(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            
            </div>
            </div>
        

            <div className='flex overflow-x-auto fixed bottom-0 left-0 right-0 bg-white dark:bg-black shadow-sm'>
                <div className='flex max-w-5xl mx-auto p-5'>
                    <input type="file" id="file-upload" accept="image/*" onChange={handleLOGOChange} className='hidden' />
                    <label className='cursor-pointer inline-block' htmlFor="file-upload">
                        <svg t="1731129524083" className="w-8 h-8 md:w-10 md:h-10 mx-2" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4279" ><path d="M514.44224 15.15008c-275.31776 0-499.30752 223.9744-499.30752 499.29728 0 275.34336 223.98976 499.31264 499.30752 499.31264 275.33312 0 499.36384-223.96928 499.36384-499.31264C1013.80608 239.12448 789.77536 15.15008 514.44224 15.15008zM788.18816 575.4624l-212.75136 0 0 212.80768L453.43744 788.27008l0-212.80256-212.7872 0L240.65024 453.44768l212.78208 0L453.43232 240.6912l121.99936 0 0 212.75648 212.7616 0L788.19328 575.4624z" fill="#7C7C7C" p-id="4280"></path></svg>
                    </label>

                    {logos.map((logoItem, index) => (
                        <img
                            key={index}
                            src={logoItem}
                            alt={`Logo ${index}`}
                            className='w-8 h-8 md:w-10 md:h-10 object-cover cursor-pointer mx-2'
                            onClick={() => handleLogoClick(logoItem)}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default Content